<template>
  <v-card class="pa-5" elevation="0">
    <v-form ref="form" lazy-validation>
      <v-row>
        <v-col cols="12" class="justify-center d-flex">
          <div v-if="form.home_cover">
            <v-img
              :src="form.home_cover"
              id="logo"
              max-width="600"
              height="auto"
              @click="onButtonClick"
            />
          </div>
          <div
            v-else
            id="upload-image"
            class="d-flex black--text justify-center align-center"
            @click="onButtonClick"
          >
            Upload Logo
          </div>
          <input
            v-show="false"
            ref="upload"
            type="file"
            accept="image/*"
            @change="onFileChanged"
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="form.cover_title"
            name="cover-title"
            label="Cover Title"
            hide-details="auto"
            dense
            outlined
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="form.cover_text"
            name="cover-text"
            label="Cover Text"
            hide-details="auto"
            dense
            outlined
          />
        </v-col>
        <v-col cols="12" class="d-flex justify-end">
          <v-btn
            :disabled="loading"
            :dark="!loading"
            class="mr-2"
            width="150"
            color="gray"
            depressed
            @click="getData"
          >
            Cancel
          </v-btn>
          <v-btn
            :loading="loading"
            width="150"
            color="#04BF68"
            dark
            depressed
            @click="saveData"
          >
            Save
          </v-btn>
        </v-col>
      </v-row>
    </v-form>

    <v-divider class="my-8"></v-divider>

    <v-row>
      <v-col cols="12" class="d-flex">
        <v-text-field
          v-model="inputGaleryCover"
          label="Cover Galery Youtube"
          dense
          outlined
          class="mr-2"
        />

        <v-btn
          :disabled="form.galery_cover === inputGaleryCover"
          fab
          small
          color="success"
          @click="saveGaleryCover"
          ><v-icon>mdi-content-save</v-icon></v-btn
        >
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { getInfo, saveInfo } from "@/api/admin/settings";
import { isURL } from "@/utils/validate";
import { uploadFile, removeFile } from "@/api/file";

export default {
  data() {
    return {
      loading: false,
      form: {
        home_cover: "",
        cover_title: "",
        cover_text: "",
      },
      inputGaleryCover: "",
      oldLogo: null,
      fileImage: null,
      fab: false,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    onButtonClick() {
      this.$refs.upload.click();
    },
    onFileChanged(e) {
      if (e.target.files.length > 0) {
        if (isURL(this.form.home_cover)) {
          this.oldLogo = this.form.home_cover;
        }
        const file = e.target.files[0];
        this.fileImage = file;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          this.form.home_cover = e.target.result;
        };
      }
    },
    snackBar(isSuccess, msg) {
      this.$store.commit("SET_SNACKBAR", {
        value: true,
        text: msg,
        color: isSuccess ? "success" : "error",
      });
    },
    getData() {
      getInfo()
        .then((res) => {
          const { data, code, message } = res.data;
          if (code) {
            this.form = data;
            this.inputGaleryCover = data.galery_cover;
          } else this.snackBar(false, message);
        })
        .catch((err) => {
          console.error("getData()\n", err);
        });
    },
    async saveGaleryCover() {
      this.form.galery_cover = this.inputGaleryCover;
      const { data } = await saveInfo(this.form);
      this.snackBar(data.code === 1, data.message);
    },
    async saveData() {
      try {
        this.loading = true;

        // Upload image
        if (this.fileImage) {
          const form = new FormData();
          form.append("file", this.fileImage);

          const uploadImage = await uploadFile(form);
          if (uploadImage.data.code) {
            this.form.home_cover = uploadImage.data.data.path;

            // delete old image, if exist
            if (isURL(this.oldLogo)) {
              const delImage = await removeFile({ file: this.oldLogo });
              if (!delImage.data.code)
                this.snackBar(false, "Failed delete image");
            }
          } else this.snackBar(false, "Failed upload image");
        }

        const response = await saveInfo(this.form);
        const { code, message } = response.data;
        this.snackBar(code === 1, message);
      } catch (error) {
        console.error("saveData()\n", error);
      } finally {
        this.loading = false;
        this.oldLogo = null;
        this.fileImage = null;
        this.getData();
      }
    },
  },
};
</script>

<style lang="scss">
#upload-image {
  border: 2px dotted gray;
  cursor: pointer;
  aspect-ratio: auto 16 / 9;
}
#logo {
  aspect-ratio: auto 16 / 9;
  cursor: pointer;
}
</style>
